/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneMostRead as ResolvedStandaloneMostRead } from 'base/components/standalone/MostRead';

export const MostRead: typeof ResolvedStandaloneMostRead = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneMostRead {...props} />
        </ErrorBoundary>
    );
});

export type MostReadProps = PropsFromComponent<typeof MostRead>;

/** @deprecated Use slot architecture instead */
export const MostReadWith = (extras: DynamicStandaloneExtras): typeof MostRead => {
    return function MostRead(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'MostRead');
        return <ResolvedStandaloneMostRead {...mergeProps({ options: { theme } }, props)} />;
    }
}