import { IconProps } from '@/components/Icon';
import { LinkProps } from '@/components/Link';
import { ClassNameProvider } from '@/styles/ArticleSlider';

import { MostReadProps } from '@/components/MostRead';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { ReactNode, useState } from 'react';
import { ArticleSlider, ArticleSliderProps } from './ArticleSlider';
import { ArticleSliderArrowProps } from './ArticleSlider.Arrow';
import { ArticleSliderLinkProps } from './ArticleSlider.Link';
import { ArticleSliderNavigationProps } from './ArticleSlider.Navigation';
import { ArticleSliderNavigationItemProps } from './ArticleSlider.Navigation.Item';
import { ArticleSliderSliderProps } from './ArticleSlider.Slider';

const findClosestElementIndex = (arr: number[], target: number): number => {
  return arr.findIndex((number) => Math.abs(number - target) === Math.min(...arr.map((n) => Math.abs(n - target))));
};

export type ArticleSliderSizes = 'medium' | 'large';

export const ArticleSliderDefaultSize: ArticleSliderSizes = 'medium';

export interface StandaloneArticleSliderProps extends StandaloneComponentProps {
  headline?: ReactNode;
  link?: LinkProps;
  caption?: ReactNode;
  icon?: IconProps;
  slides?: MostReadProps['slides'];
  options?: ArticleSliderProps & {
    $arrow?: ArticleSliderArrowProps;
    $link?: ArticleSliderLinkProps['options'];
    $navigation?: ArticleSliderNavigationProps;
    $item?: ArticleSliderNavigationItemProps;
    $slider?: ArticleSliderSliderProps['$standalone']['options'];
  };
}

enum SCROLL_STATUSES {
  START = 'START',
  END = 'END',
  SCROLL = 'SCROLL',
}

export const StandaloneArticleSlider: StandaloneComponent<StandaloneArticleSliderProps> = ({
  headline,
  link,
  caption,
  slides = [],
  options,
  ...props
}) => {
  const {
    $arrow,
    $item: itemProps,
    $link: linkProps,
    $navigation: navigationProps,
    $slider,
    size,
    ...baseProps
  } = options || {};

  const {
    $headline: sliderHeadlineProps,
    $group: sliderGroupProps,
    $caption: sliderCaptionProps,
    $description: sliderDescriptionProps,
    $slide: sliderSlideProps,
    ...sliderProps
  } = $slider ?? {};

  const { $standalone, ...arrowProps } = $arrow ?? {};
  const { options: standaloneArrowOptions, ...standaloneArrowProps } = $standalone ?? {};

  const [currentSlide, setCurrentSlide] = useState(0);
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const [scrollStatus, setScrollStatus] = useState(SCROLL_STATUSES.START);

  if (!slides?.length) {
    return null;
  }

  const { scrollWidth } = scrollRef || {};

  const scrollNeeded = scrollRef?.clientWidth !== scrollWidth;

  const breakpoints = slides.map((_, index) => {
    const diff = (scrollWidth || 1) / slides.length;
    return diff * index;
  });

  const scrollHandler = () => {
    const { scrollLeft, clientWidth } = scrollRef || {};
    const curBreakpoint = findClosestElementIndex(breakpoints, scrollLeft || 0);

    setCurrentSlide(Math.max(curBreakpoint, 0));

    if (scrollLeft === 0) {
      setScrollStatus(SCROLL_STATUSES.START);
    } else if ((scrollLeft || 0) + (clientWidth || 0) >= (scrollWidth || 1)) {
      setScrollStatus(SCROLL_STATUSES.END);
    } else {
      setScrollStatus(SCROLL_STATUSES.SCROLL);
    }
  };

  const nextSlideHandler = () => {
    const curPosition = scrollRef?.scrollLeft || 0;
    const scrollBreakpoint = breakpoints.findIndex((breakpoint) => curPosition < breakpoint);

    scrollRef?.scrollTo({ left: breakpoints[scrollBreakpoint], behavior: 'smooth' });
  };

  const prevSlideHandler = () => {
    const curPosition = scrollRef?.scrollLeft || 0;
    const scrollBreakpoint = breakpoints.findIndex((breakpoint) => curPosition <= breakpoint) - 1;

    scrollRef?.scrollTo({ left: breakpoints[scrollBreakpoint] || 0, behavior: 'smooth' });
  };

  return (
    <ClassNameProvider value={baseProps}>
      <ArticleSlider size={size} {...baseProps} {...props}>
        {scrollNeeded && scrollStatus !== SCROLL_STATUSES.END && (
          <ArticleSlider.Arrow
            size={size}
            $standalone={{
              name: 'filledArrow',
              options: { onClick: nextSlideHandler, ...standaloneArrowOptions },
              ...standaloneArrowProps,
            }}
            {...arrowProps}
          />
        )}
        {scrollNeeded && scrollStatus !== SCROLL_STATUSES.START && (
          <ArticleSlider.Arrow
            variant="left"
            size={size}
            $standalone={{
              name: 'filledArrow',
              options: { onClick: prevSlideHandler, ...standaloneArrowOptions },
              ...standaloneArrowProps,
            }}
            {...arrowProps}
          />
        )}
        <ArticleSlider.Slider
          size={size}
          $standalone={{
            headline,
            slides,
            options: {
              $headline: { colors: 'none', variant: 'none', size: 'none', ...sliderHeadlineProps },
              $caption: { variant: 'none', size: 'none', ...sliderCaptionProps },
              $description: { colors: 'none', size: 'none', ...sliderDescriptionProps },
              $group: { onScroll: scrollHandler, ref: (ref) => setScrollRef(ref), ...sliderGroupProps },
              $slide: { variant: 'none', ...sliderSlideProps },
              variant: 'none',
              ...sliderProps,
            },
          }}
        />

        {scrollNeeded && (
          <ArticleSlider.Navigation size={size} {...navigationProps}>
            {slides.map((_, index) => (
              <ArticleSlider.Navigation.Item
                key={index}
                data-active={index === currentSlide}
                size={size}
                {...itemProps}
              />
            ))}
          </ArticleSlider.Navigation>
        )}

        {link?.href && caption && (
          <ArticleSlider.Link
            {...mergeProps(
              {
                content: caption,
                options: { size, ...linkProps },
              },
              link,
            )}
          />
        )}
      </ArticleSlider>
    </ClassNameProvider>
  );
};
