import { useClassName } from '@/styles/ArticleSlider';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ArticleSliderSizes } from '.';

export interface ArticleSliderNavigationProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp<'default'>;
}

export const ArticleSliderNavigationComponent: Component<ArticleSliderNavigationProps> = ({ children, ...props }) => {
  const className = useClassName('navigation', props, {
    defaults: {
      colors: 'primary',
      size: 'ArticleSliderDefaultSize',
      variant: 'default',
    },
  });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
