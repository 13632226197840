import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const MabraStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      slider: {
        options: {
          $slider: {
            $headline: {
              className: 'text-headline-sm md:text-headline',
            },
            $caption: {
              className: 'capitalize',
            },
          },
        },
      },
      options: {
        $arrow: {
          className: 'mt-1.25 md:mt-2 w-2',
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};

export default MabraStandaloneRelatedArticleSlider;
