import { Image } from '@/components/Image';
import { Link } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { ReactNode } from 'react';
import { isObject, isString } from 'typesafe-utils';
import { MostRead, MostReadProps } from './MostRead';
import { MostReadHeadlineProps } from './MostRead.Headline';
import { MostReadSlideProps } from './MostRead.Slide';
import { MostReadSlideCaptionProps } from './MostRead.Slide.Caption';
import { MostReadSlideDescriptionProps } from './MostRead.Slide.Description';
import { MostReadSlideGroupProps } from './MostRead.Slide.Group';
import { MostReadSlideImageProps } from './MostRead.Slide.Image';

export interface MostReadSlide {
  caption?: ReactNode;
  description?: ReactNode;
  publishedUrl?: string;
  image?: JSX.Element;
}

export interface StandaloneMostReadProps extends StandaloneComponentProps {
  headline?: ReactNode;
  slides?: MostReadSlide[];
  options?: MostReadProps & {
    $caption?: MostReadSlideCaptionProps;
    $description?: MostReadSlideDescriptionProps;
    $group?: MostReadSlideGroupProps;
    $headline?: MostReadHeadlineProps;
    $image?: MostReadSlideImageProps;
    $slide?: MostReadSlideProps;
  };
}

export const StandaloneMostRead: StandaloneComponent<StandaloneMostReadProps> = ({
  headline,
  slides,
  options,
  ...props
}) => {
  const {
    $caption: captionProps,
    $description: descriptionProps,
    $group: groupProps,
    $headline: headlineProps,
    $image: imageProps,
    $slide: slideProps,
    ...baseProps
  } = options ?? {};

  return (
    <MostRead {...baseProps} {...props}>
      {headline && <MostRead.Headline {...headlineProps}>{headline}</MostRead.Headline>}
      <MostRead.Slide.Group {...groupProps}>
        {slides?.filter(isObject)?.map(({ caption, description, publishedUrl, image }, index) => (
          <MostRead.Slide key={index} {...slideProps}>
            <Link
              href={publishedUrl}
              content={
                <>
                  <MostRead.Slide.Image data-index={index} {...imageProps}>
                    {isString(image) ? <Image src={image} alt="" /> : image}
                  </MostRead.Slide.Image>
                  {caption && <MostRead.Slide.Caption {...captionProps}>{caption}</MostRead.Slide.Caption>}
                  {description && (
                    <MostRead.Slide.Description {...descriptionProps}>{description}</MostRead.Slide.Description>
                  )}
                </>
              }
            />
          </MostRead.Slide>
        ))}
      </MostRead.Slide.Group>
    </MostRead>
  );
};
