import { MostRead, type MostReadProps } from '@/components/MostRead';
import { componentClassName } from '@/styles/ArticleSlider';
import type { ClassNameProp, ExtendedStandaloneComponent } from '@/types/component';
import { ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { mergeProps } from '@/utils/mergeProps';
import { ArticleSliderDefaultSize, type ArticleSliderSizes } from '.';

export interface ArticleSliderSliderProps extends ExtendedStandaloneComponentProps<MostReadProps> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp;
}

export const ArticleSliderSliderComponent: ExtendedStandaloneComponent<ArticleSliderSliderProps> = ({
  $standalone,
  colors,
  size,
  variant,
  className,
  ...props
}) => {
  const classNameProps = { colors, size, variant };
  const classNameDefaults = {
    colors: 'primary',
    size: ArticleSliderDefaultSize,
  };

  const captionClassName = componentClassName('slider_slide_caption', classNameProps, { defaults: classNameDefaults });
  const descriptionClassName = componentClassName('slider_slide_description', classNameProps, {
    defaults: classNameDefaults,
  });
  const groupClassName = componentClassName('slider_slide_group', classNameProps, { defaults: classNameDefaults });
  const headlineClassName = componentClassName('slider_headline', classNameProps, { defaults: classNameDefaults });
  const imageClassName = componentClassName('slider_slide_image', classNameProps, { defaults: classNameDefaults });
  const slideClassName = componentClassName('slider_slide', classNameProps, { defaults: classNameDefaults });
  const baseClassName = cn(componentClassName('slider', classNameProps, { defaults: classNameDefaults }), className);

  return (
    <MostRead
      {...mergeProps(
        {
          options: {
            $caption: {
              className: captionClassName,
            },
            $description: {
              className: descriptionClassName,
            },
            $group: {
              className: groupClassName,
            },
            $headline: {
              className: headlineClassName,
            },
            $image: {
              className: imageClassName,
            },
            $slide: {
              className: slideClassName,
            },
            className: baseClassName,
          },
        },
        $standalone,
      )}
      {...props}
    />
  );
};
