import c from '@/styles/MostRead';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface MostReadSlideProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
}

export const MostReadSlideComponent: Component<MostReadSlideProps> = ({
  children,
  className,
  variant,
  colors,
  size,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('slide_variant', variant, 'default');
  const colorsClassName = getPropStyles('slide_colors', colors, 'default');
  const sizeClassName = getPropStyles('slide_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};
