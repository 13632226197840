/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneArticleSlider as ResolvedStandaloneArticleSlider } from 'base/components/standalone/ArticleSlider';

export const ArticleSlider: typeof ResolvedStandaloneArticleSlider = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneArticleSlider {...props} />
        </ErrorBoundary>
    );
});

export type ArticleSliderProps = PropsFromComponent<typeof ArticleSlider>;

/** @deprecated Use slot architecture instead */
export const ArticleSliderWith = (extras: DynamicStandaloneExtras): typeof ArticleSlider => {
    return function ArticleSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleSlider');
        return <ResolvedStandaloneArticleSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}