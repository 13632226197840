import { useClassName } from '@/styles/ArticleSlider';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ArticleSliderDefaultSize, ArticleSliderSizes } from '.';
import { ArticleSliderArrowComponent } from './ArticleSlider.Arrow';
import { ArticleSliderLinkComponent } from './ArticleSlider.Link';
import { ArticleSliderNavigationComponent } from './ArticleSlider.Navigation';
import { ArticleSliderNavigationItemComponent } from './ArticleSlider.Navigation.Item';
import { ArticleSliderSliderComponent } from './ArticleSlider.Slider';

export interface ArticleSliderProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp<'default'>;
}

export const ArticleSliderComponent: Component<ArticleSliderProps> = ({
  children,
  colors,
  size,
  variant,
  ...props
}) => {
  const className = useClassName('', {
    colors: colors ?? 'primary',
    size: size ?? ArticleSliderDefaultSize,
    variant: variant ?? 'default',
  });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const ArticleSlider = Object.assign(ArticleSliderComponent, {
  Slider: ArticleSliderSliderComponent,
  Arrow: ArticleSliderArrowComponent,
  Link: ArticleSliderLinkComponent,
  Navigation: Object.assign(ArticleSliderNavigationComponent, {
    Item: ArticleSliderNavigationItemComponent,
  }),
});
