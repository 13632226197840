import { ArticleSlider, ArticleSliderProps } from '@/components/ArticleSlider';
import { Icon, IconProps } from '@/components/Icon';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { componentTheme } from '@/styles/RelatedArticleSlider';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { ReactNode } from 'react';
import { RelatedArticleSlider } from './RelatedArticleSlider';

export interface StandaloneRelatedArticleSliderProps
  extends StandaloneComponentProps<typeof RelatedArticleSlider, { $arrow?: IconProps['options'] }> {
  headline?: ReactNode;
  slider?: ArticleSliderProps;
  link?: OptionalLinkProps;
}

export const StandaloneRelatedArticleSlider: StandaloneComponent<StandaloneRelatedArticleSliderProps> = ({
  slider,
  link,
  options,
  headline,
  ...props
}) => {
  const { $arrow, ...$base } = options ?? {};

  const defaultHeadline = (
    <OptionalLink
      content={
        <>
          <span>{headline || 'Läs artiklar om ämnet'}</span>
          <Icon
            name="sliderForwardArrow"
            options={mergeOptions(
              {
                className: `ml-1 w-1.75`,
              },
              $arrow,
            )}
          />
        </>
      }
      {...mergeProps(
        {
          options: { className: 'flex' },
        },
        link,
      )}
    />
  );

  return (
    <RelatedArticleSlider {...$base} {...props}>
      <ArticleSlider
        {...mergeProps(
          {
            headline: defaultHeadline,
            options: {
              theme: componentTheme('articleSlider'),
              $arrow: {
                name: 'sliderForwardArrow',
              },
            },
          },
          slider,
        )}
      />
    </RelatedArticleSlider>
  );
};

export default StandaloneRelatedArticleSlider;
