import { Link, LinkProps } from '@/components/Link';
import { componentTheme } from '@/styles/ArticleSlider';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export type ArticleSliderLinkProps = LinkProps;

export const ArticleSliderLinkComponent: StandaloneComponent<ArticleSliderLinkProps> = (props) => {
  return (
    <Link
      {...mergeProps(
        {
          options: {
            theme: componentTheme('link'),
          },
        },
        props,
      )}
    />
  );
};
