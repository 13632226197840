import { Icon, IconProps } from '@/components/Icon';
import { useClassName } from '@/styles/ArticleSlider';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { ArticleSliderDefaultSize, ArticleSliderSizes } from '.';

export interface ArticleSliderArrowProps extends ExtendedStandaloneComponentProps<IconProps> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp<'left' | 'right'>;
}

export const ArticleSliderArrowComponent: ExtendedStandaloneComponent<ArticleSliderArrowProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const componentClassName = cn(
    useClassName('arrow', {
      colors: colors ?? 'primary',
      size: size ?? ArticleSliderDefaultSize,
      variant: variant ?? 'right',
    }),
    standaloneClassName,
    className,
  );

  return (
    <Icon
      options={{ size: 'extraLarge', className: componentClassName, ...standaloneOptions }}
      {...standaloneProps}
      {...props}
    />
  );
};
